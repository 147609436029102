import React, {useEffect, useState} from 'react'

//components
import ChangePageTitle from '../components/change-page-title/ChangeTitle'
import ProductCard from '../components/product-card/ProductCard'
import PageLayout from '../components/page-layout'
import getProductsApi from "../services/get-products"

const Catalog = () => {
    const [products, setProducts] = useState()

    const getProducts = async () => {
    const products = await getProductsApi()
        return products
  }

    useEffect(() => {
        getProducts().then(res => {
            setProducts(res)
        })
    }, [])

  return (
    <PageLayout>
      {/* page title */}
      <ChangePageTitle pageTitle="Products" />
      {/* product card container */}
      <div className="grid grid-cols-2 md:grid-cols-4 w-full grid-flow-row gap-2 md:gap-4 px-2 text-center">
        {products?.map((product) => {
            return  <ProductCard key={product?.id} product={product} />
        })}
      </div>
    </PageLayout>
  )
}

export default Catalog
