import React from 'react'

const Footer = () => {
  return (
    <>
      <div className='w-full flex flex-col justify-center text-center gap-5 my-4 '>
          {/* footer logo */}
          <div className='flex justify-center items-center text-center py-2'>
            <img src="assets/logomini.jpg" alt="logo" className='rounded-full w-24 h-24'></img>
          </div>
          {/* follow us links */}
          <div className='flex justify-center items-center'>
            <div className='flex justify-center items-center gap-5 font-light text-sm py-4 w-full max-w-md'>
              <a className='flex-1 p-2 hover:bg-primary ease-in-out duration-300' href='https://www.instagram.com/risusheroic/' target='_blank'>INSTAGRAM</a>
              <a className='flex-1 p-2 hover:bg-primary ease-in-out duration-300' href='https://www.shopier.com/ShowProductNew/storefront.php?shop=risusheroic&sid=SzE3dUExelJrcEViaWkzczBfLTFfIF8g' target='_blank'>SHOPIER</a>
              <a className='flex-1 p-2 hover:bg-primary ease-in-out duration-300' href='https://www.etsy.com/uk/shop/risusheroic/' target='_blank'>ETSY</a>
            </div>
          </div>
          {/* copyright text */}
          <div className='flex justify-center gap-2 text-sm py-4 w-full'>
            <span>Copyright © 2022 Risus Heroic.</span>
            <span className='font-light'>All Rights Reserved.</span>
          </div>
      </div>
    </>
  )
}

export default Footer