import React from 'react';

const PageLayout = (props) => {
  return (
    <main className="max-w-screen-xl mx-auto w-full h-screen flex-1">
      {props.children}
    </main>
  )
}

export default PageLayout;