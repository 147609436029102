import { BrowserRouter, Routes, Route } from "react-router-dom";
import "../src/main.css";

//pages
import Home from "./pages/Home";
import Catalog from "./pages/Catalog";
import Detail from "./pages/Detail";
import About from "./pages/About";
import Contact from "./pages/Contact";

//components
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Layout from "./components/layout";

function App() {
    return (
        <BrowserRouter>
            <Layout>
                <Header />
                <Routes>
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/catalog/:id" element={<Detail />} />
                    <Route path="/catalog" element={<Catalog />} />
                    <Route path="/" element={<Home />} />
                </Routes>
                <Footer />
            </Layout>
        </BrowserRouter>
    );
}

export default App;
