import React from 'react'

const Hero = () => {
  return (
    <>
    <div className='w-full'>
      <img className="object-fill " src="assets/introduction-picture-x2.jpg" alt="risus-heroic-hero-banner" />
    </div>
    </>
  )
}

export default Hero