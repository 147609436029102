import React from 'react'

import ChangePageTitle from '../components/change-page-title/ChangeTitle'
import PageLayout from '../components/page-layout'

const About = () => {
  return (
    <PageLayout>
      <ChangePageTitle pageTitle="About"/>
      <div className='flex flex-col justify-start items-center md:h-[calc(100vh-192px)] overflow-auto gap-6 md:mx-8 md:gap-2'>
        {/* content container */}
        <div className='flex flex-col md:flex-row justify-start items-center gap-7 md:gap-4 font-light mx-auto my-auto w-full min-m-'>
          {/* about banner */}
          <div className='flex justify-center items-center w-full h-full'>
            <div className='w-full max-w-[500px]'>
              <img className="object-contain" src="assets/about/about-banner-2.jpg" alt="risus-heroic-about-banner" />
            </div>
          </div>
          {/* text container */}
          <div className='flex flex-col justify-between items-center w-full h-full gap-4 md:gap-0 '>
            {/* page title */}
            <h2 className='text-center font-medium text-lg w-full h-8 tracking-wide'>About Us</h2>
            {/* about text */}
            <div className='w-full text-justify flex-2 px-12 md:px-0'>
              The production of Risus Heroic is based on the recycling and reuse of old and valuable materials. It transforms old handicrafts and display objects into bags and jewelry. Each product has a different character. These characters are delivered to the people who buy them together with the product.
            </div>
            {/* empty  */}
            <div className='w-full h-8'></div>
          </div>

        </div>
      </div>
    </PageLayout>
  )
}

export default About