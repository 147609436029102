import React, { useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'

const headerNav = [
  {
    display: 'Products',
    path: '/catalog'
  },
  {
    display: 'About',
    path: '/about'
  },
  {
    display: 'Contact',
    path: '/contact'
  },
];

const Header = () => {

  const headerRef = useRef(null)

  return (
    <header ref={headerRef}>
      <div className='flex flex-row flex-wrap w-full h-40 justify-center item-center'>
        {/* brand name */}
        <div className='flex justify-center items-center w-full text-center text-2xl py-6 md:py-8 font-bold'>
          <Link to="/">RISUS HEROIC</Link>
        </div>
        {/* nav bar container */}
        <div className='w-full'>
          {/* nav bar map section */}
          <ul className='flex flex-row gap-5 justify-center font-light '>
            {
              headerNav.map((e, index) => (
                <li key={index} >
                  <Link to={e.path} className='py-2 px-4 hover:bg-primary ease-in-out duration-300'>
                    {e.display}
                  </Link>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Header 