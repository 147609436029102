import React from 'react'
import { Link } from 'react-router-dom'

const ProductCard = (product) => {
  const link = '/' + 'catalog/' + product.product.id;

  const image =  (product?.product?.fields?.image[0]?.url)

  return (
    <Link to={link}>
      <div key={product.id} className='flex flex-col justify-end item-center relative h-60 overflow-hidden group rounded-sm'>
        <div className='flex-1 w-full h-full overflow-hidden bg-primary'>
          <img className='w-full h-full object-contain group-hover:scale-110 ease-in-out duration-300' src={image} alt=''/>
        </div>
        <div className='text-center p-2 flex-shrink-0 bg-primary/50'>
          {product?.product?.fields?.name}
        </div>
      </div>
    </Link>
  )
}

export default ProductCard