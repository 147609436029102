import React from 'react';
import { useLocation } from 'react-router-dom'

const Layout = (props) => {

  const location = useLocation();

  const isHome = location.pathname === '/';
  
  return (
    <div className={`flex flex-col ${isHome ? ' ' : 'gap-6 md:gap-8'} min-h-screen `}>
    {props.children}
    </div>
  )
}

export default Layout;