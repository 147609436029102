import React from 'react'

//components
import ChangePageTitle from '../components/change-page-title/ChangeTitle'
import PageLayout from '../components/page-layout'

const Contact = () => {
  return (
    <PageLayout>
      <div className='flex flex-col justify-start items-center max-w-3xl mx-10 md:mx-auto md:h-[calc(100vh-192px)] md:overflow-auto'>
        {/* change page title component */}
        <ChangePageTitle pageTitle='Contact'/>
        {/* page contents */}
        <div className='flex flex-col gap-5'>
          <div className='flex flex-col gap-2'>
            {/* page title */}
            <h2 className='font-semibold tracking-wide'>Contact</h2>
            {/* introduction */}
            <p className='text-justify font-light' >If you've got any questions regarding my work, sales or anything else please don't hesitate to get in touch. I'll try to get back to you as soon as possible. </p>
          </div>
          {/* e-mail address */}
          <div className='flex flex-col gap-2'>
            <h3 className='font-semibold tracking-wide'>Email</h3>
            <p className='font-light'>risusheroic@gmail.com</p>
          </div>
          {/* links from risus heroic */}
          <div className='flex flex-col gap-4 mt-6'>
            {/* links title */}
            <h2 className='font-semibold tracking-wide'>Follow Us</h2>
            {/* social media */}
            <div className='flex flex-col gap-4 justify-center items-start'>
              <a className='flex justify-center items-center text-center gap-2 h-8 group transition-all ease-in-out duration-300' href='https://www.instagram.com/risusheroic/' target='_blank'>
                <div className='w-8 h-8 group-hover:text-[#f9ce34]'>
                  <ion-icon name="logo-instagram"></ion-icon>
                </div>
                <div className='flex justify-center items-center w-full h-8 mt-1'>
                  <p className='text-sm font-normal hidden group-hover:inline-block text-transparent bg-clip-text group-hover:bg-gradient-to-r from-[#f9ce34] from-10% via-[#ee2a7b] via-50% to-[#6228d7] to-90% transition-all ease-in-out duration-300'>INSTAGRAM</p>
                  <p className='text-sm font-normal group-hover:hidden transition-all ease-in-out duration-300'>INSTAGRAM</p>
                </div>
              </a>
              {/* shopier */}
              <a className='flex justify-center items-center gap-2 hover:text-[#6900ff] transition-all ease-in-out duration-300' href='https://www.shopier.com/ShowProductNew/storefront.php?shop=risusheroic&sid=SzE3dUExelJrcEViaWkzczBfLTFfIF8g' target='_blank'>
                <div className='w-8 h-8'>
                  <ion-icon name="basket-outline"></ion-icon>
                </div>
                <p className='flex text-center items-center justify-center text-sm font-normal mt-1.5'>SHOPIER</p>
              </a>
              {/* etsy shop */}
              <a className='flex justify-center items-center gap-2 h-8 hover:text-[#ff6707] transition-all ease-in-out duration-300' href='https://www.etsy.com/uk/shop/risusheroic/' target='_blank'>
                <div className='w-8 h-8'>
                  <ion-icon name="storefront-outline"></ion-icon>
                </div>
                <p className='flex text-center items-center justify-center text-sm font-normal mt-1.5'>ETSY</p>
              </a>
              
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default Contact