import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import Slider from "react-slick";

import ChangePageTitle from '../components/change-page-title/ChangeTitle';
import PageLayout from '../components/page-layout';
import getProductDetail from "../services/get-product"

const Detail = () => {

  const [product, setProduct] = useState()
  const { id } = useParams();

  const getProducts = async () => {
    const product = await getProductDetail(id)
      return product
  }

  useEffect(() => {
    getProducts().then(res => {
      setProduct(res)
    })
  }, [id])

  // slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1
  };

  return (
    <PageLayout>
      <div className='flex flex-col justify-start items-center text-center gap-8'>
        {/* page title */}
        <ChangePageTitle pageTitle={product?.fields?.name} />
        {/* image container */}
        <div className='w-full md:max-w-[380px] flex flex-col bg-primary mb-2'>
          <Slider {...settings}>
            {
              product?.fields?.images.map((image, i) => (
              <img key={i} className='object-contain aspect-square' src={image?.url} alt='product-photo' /> 
              ))
            }
          </Slider>
        </div>
        {/* text container */}
        <div className='flex flex-col justify-center max-w-[750px] min-w-[300px] mx-12 gap-4 md:mx-0'>
          {/* product name */}
          <div className='flex justify-center items-center text-lg h-12'>
            {product?.fields?.name}
          </div>
          {/* product info */}
          <div className='flex justify-center items-center text-justify font-light'>
          {product?.fields?.info}
          </div>
        </div>
        {/* shop links */}
        <div>
          {
            !product?.fields?.soldOut ? (
              <div className='flex flex-col justify-center items-center gap-3'>
                <div className='w-60 h-12 bg-black cursor-pointer'>
                  <button href={product?.fields?.etsyLink} target='_blank' className={`${product?.fields?.etsyLink ? 'w-full pointer-events-auto hover:bg-[#ff6707]' : ' w-full pointer-events-none bg-[#ff4757] select-none'} flex justify-center items-center h-full text-white font-semibold bg-black transition-all ease-in-out duration-300`}>
                    {product?.fields?.etsyLink ? 'SHOP NOW ON ETSY' : 'SOLD OUT'}
                  </button>
                </div>
                <div className='w-60 h-12 bg-black cursor-pointer'>
                  <button href={product?.fields?.shopifyLink} target='_blank' className={`${product?.fields?.shopierLink ? 'w-full pointer-events-auto hover:bg-[#6900ff]' : ' w-full pointer-events-none bg-[#ff4757] select-none'} flex justify-center items-center h-full text-white font-semibold bg-black transition-all ease-in-out duration-300`}>
                    {product?.fields?.shopierLink ? 'SHOP NOW ON SHOPIER' : 'SOLD OUT'}
                  </button>
                </div>
              </div>
            ) : (
              <div className='w-60 h-12 bg-black cursor-pointer'>
                <div className='w-full pointer-events-none bg-[#ff4757] select-none flex justify-center items-center h-full text-white font-semibold'>
                  SOLD OUT
                </div>
              </div>
            )
          }
        </div>
      </div>
    </PageLayout>
  )
} 

export default Detail